.tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tag__name {
  margin-left: 10px;
  font-size: 14px;
}
.tag > .MuiAvatar-root {
  width: 32px;
  height: 32px;
}
