.report {
  display: flex;
  height: 400px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.report__header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #a5a5a5;
}
.report__headerText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
  font-size: 12px;
  font-weight: normal;
}
.report__headerText b {
  font-size: 16px;
}
.report__headerText p {
  color: #a5a5a5;
}
.report__option {
  margin-top: 30px;
}

.report__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  margin: 0 10px 5px;
}
.report__contentText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 80%;
}
.report__contentText b {
  font-size: 14px;
}
.report__contentText p {
  color: #a5a5a5;
  font-size: 11px;
}
.report__content > span {
  margin-left: 10px;
  color: #3c3c3c;
}
