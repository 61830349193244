.thread {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.thread__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 8px;
  padding-bottom: 6px;
  font-weight: 700;
  border-left: 1px solid lightgrey;
}
.thread__headerInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.thread__headerInfo > span:first-of-type {
  color: #4f3951;
}
.thread__headerInfo > span:last-of-type {
  color: #7a7a7a;
  font-size: 12px;
  font-weight: lighter;
}
.thread__header > .MuiButtonBase-root {
  margin-left: 10px;
  margin-right: 20px;
  width: 20px;
  height: 20px;
  outline: none;
}
.thread__content {
  flex: 1;
  border-top: 1px solid lightgrey;
  overflow: scroll;
}
.thread__imgContainer {
  max-height: 400px;
  min-height: 200px;
  background: #dfdfdf;
  position: relative;
}
.thread__image {
  max-width: 340px;
  max-height: 400px;
  object-fit: contain;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin: 5px 30px 0;
}
.thread__video {
  max-width: 340px;
  max-height: 400px;
  object-fit: contain;
  outline: none;
  display: block;
  margin: 0 auto;
}
.threadReply {
  display: flex;
  flex-direction: column;
}
.threadReply_content {
  margin: 0 20px;
  margin-left: 67px;
  font-size: 13px;
}
.threadReply_clap {
  text-align: left;
  margin-left: 60px;
}
.threadReply_clapCount {
  font-size: 12px;
}
.thread__commentsInfo {
  flex: 1;
  padding: 0 15px;
  color: black;
}
.threadReply__file {
  margin: 10px 0;
  display: flex;
  padding: 10px;
  background-color: #cfeee7;
  border: 1px solid #cfeee7;
  border-radius: 4px;
}
.threadReply__fileInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.threadReply__fileIcon {
  background-size: 44px 56px;
}
.threadReply__fileInfo span {
  font-size: 10px;
}
.threadReply__fileInfo span:first-of-type {
  font-weight: 600;
}
.threadReply__audio {
  margin-top: 10px;
}
.thread__footer {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.thread__footer > .MuiAvatar-root {
  width: 32px;
  height: 32px;
  margin-left: 20px;
}
.thread__footerInput {
  flex: 1;
  display: flex;
  height: 60px;
}
.thread__footerInput > form {
  flex: 1;
  display: flex;
}
.thread__footerInput > form > input {
  font-size: 14px;
  padding: 10px;
  padding-bottom: 5px;
  border: none;
  outline: none;
}
.thread__footerIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 140px;
  margin-right: 5px;
  position: relative;
}
.thread__footerIcons aside.emoji-picker-react {
  position: absolute !important;
  right: 10px !important;
  width: 350px !important;
  height: 270px !important;
  bottom: 60px !important;
}
.thread__footerIcons .MuiSvgIcon-root {
  color: gray;
  width: 18px;
  height: 18px;
  margin: 10px 5px;
}
.thread__footerIcons > .MuiIconButton-root {
  padding: 12px 0;
}
.threadReply__imgContainer {
  height: 150px;
  width: 85%;
  background: #dfdfdf;
  position: relative;
  border: 1px solid lightgray;
  border-radius: 4px;
  margin-bottom: 10px;
}
.threadReply__image {
  display: block;
  max-width: 100%;
  max-height: 170px;
  object-fit: contain;
  border: 1px solid lightgray;
  border-radius: 4px;
  margin-bottom: 10px;
}
.threadReply__video {
  max-width: 400px;
  max-height: 170px;
  object-fit: contain;
  outline: none;
  display: block;
  border-radius: 4px;
  margin-bottom: 10px;
}
.reply__meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #4f3951;
  font-size: 12px;
}
.reply__badge {
  display: inline-flex;
  margin-left: 67px;
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  .threadReply__video {
    max-width: 90%;
  }
  .threadReply_content {
    margin-left: 65px;
  }
  .reply__badge {
    margin-left: 65px;
  }
}
