.notification {
  display: flex;
  justify-content: space-between;
}
.notification__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
}
.notification__title {
  color: #333333;
  font-size: small;
}
.notification__info {
  display: flex;
  align-items: center;
}
.notification__group {
  color: #2bb793;
  font-size: 11px;
  font-weight: 500;
  margin-right: 10px;
}
.notification__time {
  color: #707070;
  font-size: 11px;
}
.notification .MuiAvatar-root {
  width: 32px;
  height: 32px;
}
.notification__btn {
  margin-left: 10px;
  display: inline-flex;
  color: #fff;
  font-size: 8px;
  font-weight: 600;
  padding: 2px 4px;
  background-color: #fc9f5b;
  border-radius: 4px;
  height: fit-content;
}
