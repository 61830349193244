.audio__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.audio__buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.audio__buttons .MuiSvgIcon-root {
  width: 40px;
  height: 40px;
}
.audio__timer {
  min-width: 80px;
  color: #4f3951;
  font-size: 16px;
  text-align: center;
}
