.clap__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4f3951;
  color: #ffffff;
  height: 50px;
}
.clap__header > .MuiIconButton-root {
  color: #fff;
}
.clap__headerIcons {
  padding: 20px;
  display: flex;
  align-items: center;
}
.clap__headerIcons > span {
  margin-left: 20px;
}

.clap__content {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.clapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 70px;
}
.clap__details {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  color: #5a5a5a;
  font-size: 12px;
}
.clap__details > span {
  margin-top: 2px;
}
.clap__name {
  color: #4f3951;
  font-weight: 500;
  font-size: 14px;
}
.clapper > span {
  font-size: 12px;
  color: #474747;
}
.clap__content hr {
  width: 85%;
  margin-left: 48px;
}
.clap__content hr:last-child {
  display: none;
}
