.feed__footer {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid lightgray;
  border-radius: 4px;
  margin: 0 15px;
  position: relative;
}
.hide__footer {
  display: none;
}
.feed__footerButton {
  position: absolute;
  left: 360px;
  bottom: -18px;
}
.feed__footerButton > .MuiButtonBase-root {
  display: flex;
  width: 48px;
  height: 48px;
  bottom: 20px;
  color: gray;
  margin-left: auto;
  outline: none;
  background-color: #2bb793;
  color: white;
}
.feed__footerButton > .MuiButtonBase-root:hover {
  background-color: #2b8d75;
}
.feed__footer > .MuiButtonBase-root {
  display: flex;
  position: absolute;
  width: 30px;
  height: 30px;
  right: 5px;
  top: -50px;
  color: gray;
  margin-left: auto;
  outline: none;
  background-color: lightgray;
}

.feed__footerSeed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  height: 42px;
}

.feed__footerIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-width: 180px;
  margin-right: 5px;
}
.feed__footerIcons .MuiSvgIcon-root {
  color: gray;
  width: 20px;
  height: 20px;
  margin: 10px 6px;
}
.feed__footerIcons > .MuiIconButton-root {
  padding: 12px 0;
}

.feed__footerIcons aside.emoji-picker-react {
  position: absolute !important;
  left: 0px !important;
  bottom: 62px !important;
  width: 350px !important;
  height: 270px !important;
  overflow: scroll;
}

.feed__footerInput {
  border-bottom: 1px solid lightgray;
  overflow: hidden;
}
.feed__footerInput > form {
  flex: 1;
  display: flex;
}
.feed__footerInput > form > textarea {
  flex: 1;
  border-radius: 30px;
  font-size: 14px;
  padding: 10px;
  padding-bottom: 5px;
  border: none;
  outline: none;
}
.feed__footerInput > form > button {
  outline: none;
}
.pasted__image--container {
  padding: 8px;
  display: flex;
  flex-direction: column;
  border: 2px solid #e0e0e0;
}
.preview__container--control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  border-bottom: 2px solid #e0e0e0;
}
.preview__container--control > p {
  margin: 0;
  padding: 4px;
}
.cancel__pasted-image--preview {
  width: 120px;
  height: max-content;
  padding: 8px 4px;
  border-radius: 4px;
  outline: none;
  border: #fff;
  cursor: pointer;
  font-weight: 600;
  margin: 4px 0;
}
.preview__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.preview__caption--container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.preview__caption--container > textarea {
  width: 100%;
  padding: 4px;
  margin-right: 8px;
  border: 1px solid #e0e0e0;
}
.preview__container > img {
  max-height: 300px;
  width: 100%;
  max-width: 1100px;
  object-fit: contain;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
}
.send__preview--message {
  width: 120px;
  height: max-content;
  padding: 8px 4px;
  border-radius: 4px;
  outline: none;
  border: #fff;
  cursor: pointer;
  font-weight: 600;
}

.tag__suggestions {
  width: 100%;
  max-height: 250px;
  overflow-y: scroll;
  border: 1px solid #7a7a7a;
  border-radius: 5px;
  border-bottom: 1px solid transparent;
}

@media (max-width: 767px) {
  .feed__footer {
    border-left: none;
    border-right: none;
    bottom: 15px;
    margin: 0;
  }
  .feed__footerButton > .MuiButtonBase-root {
    position: absolute;
    right: 5px;
    bottom: 110px;
  }
}
