.post {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 460px;
  border: 1px solid lightgray;
  margin-bottom: 45px;
}

.post__meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 0;
  color: #4f3951;
  font-weight: 400;
}

.post__header {
  display: flex;
  align-items: center;
  padding: 20px;
}
.post__header > .MuiAvatar-root {
  width: 48px;
  height: 48px;
}

.post__headerInfo {
  flex: 1;
  padding-left: 15px;
}

.post__headerMeta > h3 {
  color: #4f3951;
  font-weight: 550;
  font-size: 14px;
  margin: 0px 8px 2px 0px;
  line-height: 1.29;
}

.post__headerInfo > p {
  color: #7a7a7a;
  font-size: 11px;
  margin-bottom: 0;
  color: #373737;
}
.post__headerMeta {
  display: flex;
  align-items: center;
}
.post__imgContainer {
  min-height: 200px;
  background: #dfdfdf;
  position: relative;
}

.post__image {
  max-width: 340px;
  max-height: 400px;
  object-fit: contain;
  border: 1px solid transparent;
  border-radius: 6px;
  margin: 10px 0 0 20px;
}
.post__video {
  max-width: 340px;
  max-height: 400px;
  outline: none;
  display: block;
  margin: 0 auto;
}
.post__audio {
  margin-top: 10px;
  margin-left: 20px;
}
.post__file {
  margin: 10px 20px;
  display: flex;
  padding: 10px;
  background-color: #cfeee7;
  border: 1px solid #cfeee7;
  border-radius: 4px;
}
.post__fileIcon {
  background-size: 44px 56px;
}
.post__fileInfo {
  padding-left: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.post__fileInfo span {
  font-size: 10px;
}
.post__fileInfo span:first-of-type {
  font-weight: 600;
}

.post__text {
  font-weight: normal;
  font-size: 15px;
  white-space: pre-line;
  padding: 0 20px 8px 20px;
}
.taggedUser {
  color: #005cbf;
}
.post__links {
  margin: 0 20px 10px 20px;
}
.post__info {
  display: flex;
  justify-content: space-between;
  color: #7a7a7a;
  font-size: 12px;
  margin-bottom: 3px;
}
.post__info > span {
  margin: 0 20px;
}

.post__info span:first-of-type {
  text-decoration: underline;
}

.post__icons {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(228, 228, 228);
}
.post__reply {
  padding-top: 5px;
  margin: 0 20px 5px;
  display: flex;
  justify-content: space-between;
  color: #2bb793;
}
.post__replyDisabled {
  color: #bdbdbd;
}
.post__icons > .MuiButton-root {
  color: #7a7a7a;
}
.post__icons > .MuiButton-textSizeSmall {
  font-size: 12px;
  padding: 4px 20px;
}

.post__comments {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.post__comments > .MuiAvatar-root {
  width: 30px;
  height: 30px;
}

.post__commentsInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}
.post__commentsInfo > span:first-of-type {
  font-size: 12px;
  margin-bottom: 6px;
  margin-top: 3px;
}
.post__commentsInfo > span:last-of-type {
  font-size: 13px;
  color: #7a7a7a;
  width: 90%;
}
.post__commentsRight {
  display: flex;
  align-items: center;
  color: #7a7a7a;
  font-size: 12px;
  margin-bottom: 3px;
}
.post__commentsRight > span {
  margin-right: 5px;
}
.post__commentsRight .MuiIconButton-root {
  width: 30px;
  height: 30px;
}
.post__replyCollapse {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  border: 1px solid lightgray;
  border-radius: 4px;
}

.post__commentBox {
  display: flex;
  background-color: #f8f8f8;
}

@media (max-width: 767px) {
  .post {
    max-width: 100%;
    margin-bottom: 30px;
    border: none;
  }
  .post__links {
    max-width: 300px;
  }
}
