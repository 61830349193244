.post__replyCollapse {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  border: 1px solid lightgray;
  border-radius: 4px;
}

.post__commentBox {
  display: flex;
  background-color: #f8f8f8;
}

.post__input {
  flex: 1;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  border: none;
  background-color: #f8f8f8;
}

.feed__footerSeed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  height: 42px;
}

.feed__footerIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-width: 180px;
  margin-right: 5px;
}
.feed__footerIcons .MuiSvgIcon-root {
  color: gray;
  width: 20px;
  height: 20px;
  margin: 10px 6px;
}
.feed__footerIcons > .MuiIconButton-root {
  padding: 12px 0;
}

.feed__footerIcons aside.emoji-picker-react {
  position: absolute !important;
  left: 0px !important;
  bottom: 62px !important;
  width: 350px !important;
  height: 270px !important;
  overflow: scroll;
}
.tag__suggestions {
  width: 100%;
  max-height: 250px;
  overflow-y: scroll;
}
