.userInfo__header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4f3951;
  color: #ffffff;
  height: 50px;
}
.userInfo__header > .MuiIconButton-root {
  color: #fff;
}
.userInfo__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.userInfo__card {
  width: 90%;
  padding: 20px;
  display: flex;
  max-height: 200px;
  justify-content: space-between;
  background-color: lightgray;
  height: 200px;
  margin: 20px 0;
  border: 1px solid lightgray;
  border-radius: 5px;
}
.userInfo__details {
  padding-left: 20px;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #6e6e6e;
}
.userInfo__details > div {
  margin: 4px 0;
}
.userInfo__cardProfile {
  min-height: 160px;
}
.userInfo__imgContainer {
  width: 110px;
  min-height: 150px;
  background: #dfdfdf;
  position: relative;
  border-radius: 5px;
}
.userInfo__Image {
  max-width: 110px;
  max-height: 160px;
  object-fit: contain;
  border-radius: 5px;
  border: 1px solid #9c9c9c;
  display: block;
}
.userInfo__name {
  font-weight: 900;
  color: #4f3951;
}
.userInfo__jobTitle {
  text-align: right;
}
.userInfo__location {
  display: flex;
  align-items: center;
}
.userInfo__stats {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 5px;
}
.userInfo__clap {
  padding: 20px;
  display: flex;
  align-items: center;
}
.userInfo__clapInfo {
  margin-left: 20px;
}
.userInfo__statDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 20px;
}
.userInfo__count {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #4f3951;
  padding: 0 15px;
}
.userInfo__statDetails div:last-child {
  border-right: none;
}
.userInfo__groupCaption {
  width: 90%;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  margin: 20px 0 0 20px;
  color: #4f3951;
}
.userInfo__groupCaption > hr {
  width: 90%;
  margin: 10px 0;
}
.userInfo__groupDetails {
  width: 90%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: scroll;
  max-height: 210px;
  font-size: small;
  margin-bottom: 15px;
}
.userInfo__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}
.userInfo__groupImage {
  width: 24px;
  height: 24px;
  margin: 0 10px 0 5px;
}
