.badge {
  font-size: 9px;
  max-width: fit-content;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.31px;
  max-height: 16px;
  padding: 1px 4px;
  line-height: 1.39;
  border-radius: 4px;
  color: #fff;
  text-align: center;
}
.badge__container {
  display: inline-flex;
  max-height: 16px;
}
.badge__count {
  padding: 0 3px 2px 4px;
  border-radius: 4px 0px 0px 4px;
  background-color: #ffd166;
  font-size: 9px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4d3951;
  padding: 2px;
}

.badge__label {
  border-radius: 0px 4px 4px 0px;
  background-image: linear-gradient(to top, #fe5196, #f77062);
  font-size: 9px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: center;
  color: #ffffff;
  padding: 2px;
}
