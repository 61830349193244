.media__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  margin-bottom: 5px;
}
.media__headerDetails {
  display: flex;
  align-items: center;
}
.media__headerInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
}
.media__content {
  height: 100%;
  display: flex;
  justify-content: center;
}
.media__image {
  max-width: 100%;
  max-height: 90%;
  object-fit: contain;
  margin: 0 auto;
}
.media__contentFile {
  width: 90%;
  height: 90%;
  overflow-y: scroll;
  object-fit: contain;
  margin: 0 auto;
}
.video {
  outline: none;
}
.media__timeStamp {
  font-size: 12px;
  color: #929292;
}
