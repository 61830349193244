.mediaupload__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mediaupload__headerText {
  color: #4f3951;
  font-size: medium;
  font-weight: 600;
}
.mediaupload {
  display: flex;
  height: 400px;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
.mediaupload > input[type="file"] {
  margin: 10px 3px;
}

.mediaupload > input[type="text"] {
  padding: 10px;
  margin: 10px 3px;
  outline: none;
}
.mediaupload textarea {
  padding: 10px;
  margin: 10px 3px;
  outline: none;
}
.mediaupload > div > p {
  margin-bottom: 10px;
  margin-left: 3px;
  font-size: small;
}
.mediaupload > video {
  max-width: 320px;
}
.tag__suggestions {
  width: 100%;
  max-height: 250px;
  overflow-y: scroll;
}

.mediaupload__inputPanel {
  display: flex;
  align-items: center;
  position: relative;
}
.mediaupload__inputPanel > textarea {
  flex: 1;
}
.mediaupload__inputPanel aside.emoji-picker-react {
  position: absolute !important;
  left: 350px !important;
  bottom: 0px !important;
  width: 350px !important;
  height: 350px !important;
  overflow: scroll;
  z-index: 999;
}

@media (max-width: 767px) {
  .feed__body {
    padding: 0;
  }

  .mediaupload__inputPanel aside.emoji-picker-react {
    position: absolute !important;
    left: 0px !important;
    bottom: 80px !important;
    width: 350px !important;
    height: 350px !important;
    overflow: scroll;
    z-index: 999;
  }
}
