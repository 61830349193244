.feed {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #ededed;
}
.feed__header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #4f3951;
  color: #ffffff;
  height: 60px;
  padding-left: 15px;
}
.feed__headerLeft {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.feed__headerRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.feed__headerMobile {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.feed__header .MuiButtonBase-root {
  color: #fff;
}
.feed__headerDetail {
  flex: 1;
  display: flex;
  align-items: center;
}
.feed__headerInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 15px;
}
.feed__header > .MuiAvatar-root {
  width: 36px;
  height: 36px;
}

.feed__headerInfo > h3 {
  margin: 0;
  font-weight: 500;
  font-size: 15px;
}

.feed__headerInfo > p {
  margin-bottom: 3px;
  font-size: 11px;
  font-weight: 500;
}

.feed__body {
  flex: 1;
  padding: 30px;
  padding-left: 15px;
  padding-bottom: 0;
  overflow: scroll;
  margin-bottom: 20px;
}

.feed__body > .MuiButtonBase-root {
  display: flex;
  position: fixed;
  width: 32px;
  height: 32px;
  right: 420px;
  top: 150px;
  color: gray;
  margin-left: auto;
  outline: none;
  background-color: lightgray;
}
.feed_bodyButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: lightgray;
  width: 40%;
  margin: 0 auto;
  margin-bottom: 15px;
}
.feed_bodyButton > p {
  margin-top: 12px;
  font-size: 14px;
}

.feed_bodyButton > .MuiButton-root {
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 12px;
}

.post {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 500px;
  border: 1px solid lightgray;
  margin-bottom: 45px;
}

.post__meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 0;
  color: #4f3951;
  font-weight: 400;
}

.post__header {
  display: flex;
  align-items: center;
  padding: 20px;
  max-height: 65px;
}

.post__headerInfo {
  flex: 1;
  padding-left: 15px;
}

.post__headerInfo > h3 {
  color: #4f3951;
  font-weight: 530;
  font-size: medium;
  margin-bottom: 3px;
  margin-top: 8px;
}

.post__headerInfo > p {
  color: #7a7a7a;
  font-size: 12px;
  margin-bottom: 3px;
}
.post__imgContainer {
  min-height: 200px;
  background: #dfdfdf;
  position: relative;
}

.post__image {
  width: 100%;
  object-fit: contain;
  border-bottom: 1px solid lightgray;
}
.post__video {
  width: 100%;
  outline: none;
  display: block;
  margin: 0 auto;
}
.post__audio {
  margin-top: 10px;
  margin-left: 20px;
}
.post__file {
  margin: 10px 20px;
  display: flex;
  padding: 10px;
  background-color: #cfeee7;
  border: 1px solid #cfeee7;
  border-radius: 4px;
}
.post__fileIcon {
  background-size: 44px 56px;
}
.post__fileInfo {
  padding-left: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.post__fileInfo span {
  font-size: 10px;
}
.post__fileInfo span:first-of-type {
  font-weight: 600;
}

.post__text {
  font-weight: normal;
  font-size: 15px;
  word-break: break-word;
  padding: 0 20px 8px 20px;
}
.taggedUser {
  color: #005cbf;
}
.post__links {
  margin: 0 20px 10px 20px;
}
.post__info {
  display: flex;
  justify-content: space-between;
  color: #7a7a7a;
  font-size: 12px;
  margin-bottom: 3px;
}
.post__info > span {
  margin: 0 20px;
}

.post__info span:first-of-type {
  text-decoration: underline;
}

.post__icons {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(228, 228, 228);
}
.post__reply {
  padding-top: 5px;
  margin: 0 20px 5px;
  display: flex;
  justify-content: space-between;
  color: #2bb793;
}
.post__icons > .MuiButton-root {
  color: #7a7a7a;
}
.post__icons > .MuiButton-textSizeSmall {
  font-size: 12px;
  padding: 4px 20px;
}

.post__comments {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.post__comments > .MuiAvatar-root {
  width: 30px;
  height: 30px;
}

.post__commentsInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}
.post__commentsInfo > span:first-of-type {
  font-size: 12px;
  margin-bottom: 6px;
  margin-top: 3px;
}
.post__commentsInfo > span:last-of-type {
  font-size: 13px;
  color: #7a7a7a;
  width: 90%;
}
.post__commentsRight {
  display: flex;
  align-items: center;
  color: #7a7a7a;
  font-size: 12px;
  margin-bottom: 3px;
}
.post__commentsRight > span {
  margin-right: 5px;
}
.post__commentsRight .MuiIconButton-root {
  width: 30px;
  height: 30px;
}
.post__replyCollapse {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  border: 1px solid lightgray;
  border-radius: 4px;
}

.post__commentBox {
  display: flex;
  background-color: #f8f8f8;
}

.post__input {
  flex: 1;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  border: none;
  background-color: #f8f8f8;
}

.post__button {
  flex: 0;
  border: none;
  border-top: 1px solid lightgray;
  color: #6082a3;
}
.tag__suggestions {
  width: 100%;
  max-height: 250px;
  overflow-y: scroll;
  background-color: #fff;
}
hr {
  margin: 0;
}

.MuiMenuItem-root {
  max-width: 300px;
  white-space: normal !important;
}

.post__success {
  color: #2bb793;
  margin-left: 20px;
}
.post__error {
  color: #f85963;
  margin-left: 20px;
}
audio {
  outline: none;
}

.mediaupload__inputPanel {
  display: flex;
  align-items: center;
  position: relative;
}
.mediaupload__inputPanel > textarea {
  flex: 1;
}
.mediaupload__inputPanel aside.emoji-picker-react {
  position: absolute !important;
  left: 350px !important;
  bottom: 0px !important;
  width: 350px !important;
  height: 350px !important;
  overflow: scroll;
  z-index: 999;
}

@media (max-width: 767px) {
  .feed__header {
    align-items: flex-start;
    height: auto;
    padding: 5px 5px 0 5px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999;
  }
  .feed__header .MuiButtonBase-root {
    margin-right: 0;
  }
  .feed__headerLeft {
    flex-direction: column;
    align-items: flex-start;
  }
  .feed__headerRight {
    align-items: flex-start;
    margin-right: 10px;
  }

  .feed__headerDetail {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .feed__headerDetail .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
  .feed__headerInfo {
    padding: 0 8px;
    padding-top: 8px;
  }

  .feed__body {
    padding: 0;
    margin-bottom: 5px;
  }
  .feed_bodyButton {
    width: 65%;
    margin-top: 100px;
  }

  .mediaupload__inputPanel aside.emoji-picker-react {
    position: absolute !important;
    left: 0px !important;
    bottom: 80px !important;
    width: 350px !important;
    height: 350px !important;
    overflow: scroll;
    z-index: 999;
  }
  .feed__headerInfo > p {
    display: none;
  }
  .feed__headerInfo > h3 {
    margin: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
  }
  .post {
    max-width: 100%;
    margin-bottom: 30px;
    border: none;
  }
  .post__links {
    max-width: 300px;
  }
}
