.feed__SelectSeed {
  display: flex;
  align-items: center;
}

.feed__SelectSeed > .MuiAvatar-root {
  width: 30px;
  height: 30px;
  margin: 5px;
  border: 1px solid white;
  margin-left: 10px;
}
@media (max-width: 767px) {
  .feed__SelectSeed {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .feed__SelectSeed .MuiSelect-select.MuiSelect-select {
    max-width: 100px;
  }
}
