.sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  z-index: 1111;
}

.sidebar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-right: 1px solid lightgray;
  background-color: #4f3951;
  height: 60px;
  padding-right: 10px;
}
.sidebar__headerRight {
  display: flex;
}
.sidebar__logo {
  flex: 1;
  width: 121px;
  height: 34px;
  margin: 6px 0 12px 0;
  font-family: FiraSans;
  font-size: 24px;
  font-weight: 900;
  color: #fc9f5b;
}
.sidebar__logoEnd {
  color: #fff;
}
.sidebar__header button .MuiSvgIcon-root {
  color: #e0e0e0;
}
.sidebar__headerAvatar {
  margin: 10px auto;
}
.sidebar__headerMenu {
  display: flex;
  justify-content: space-between;
  font-size: x-small;
}

.sidebar__headerMenu .MuiSvgIcon-root {
  margin-right: 5px;
}
.sidebar__headerLogout {
  margin-left: 15px;
  font-size: small;
}
.sidebar__search {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 40px;
  border-bottom: 1px solid #ebebeb;
}

.sidebar__search > .MuiSvgIcon-root {
  color: gray;
  margin: 10px;
  margin-left: 40px;
  font-size: 18px;
}

.sidebar__search > input {
  border: none;
  outline: none;
  margin-left: 10px;
  flex: 1;
  font-size: small;
}
.sidebar__chat {
  background-color: #fff;
  flex: 1;
  overflow: scroll;
}

.sidebarChat {
  display: flex;
  padding: 10px;
  cursor: pointer;
  max-height: 60px;
  border-bottom: 1px solid #ebebeb;
}
.sidebarChat .MuiAvatar-root {
  margin-left: 8px;
  height: 38px;
  width: 38px;
}
.sidebarChat .MuiBadge-root > .MuiAvatar-root {
  margin-left: 8px;
  height: 38px;
  width: 38px;
}

.sidebarChat__info {
  flex: 1;
  margin-left: 15px;
}
.sidebarChat__info > h2 {
  font-size: 13px;
  font-weight: 500;
  margin: auto 0;
  margin-top: 8px;
}
