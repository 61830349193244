* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html,
body,
.app,
.dashboard {
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.dashboard {
  background-color: #ededed;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.feed_container {
  height: 100vh;
}
