.reportMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.reportMessage__header {
  display: flex;
  width: 95%;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 10px;
  border-bottom: 1px solid #a5a5a5;
  margin-bottom: 30px;
}
.reportMessage__headerText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
  font-size: 12px;
  font-weight: normal;
}
.reportMessage__headerText b {
  font-size: 16px;
}

.reportMessage__infoText {
  width: 100%;
  text-align: left;
  padding-left: 10px;
  margin-top: -20px;
  color: #f85963;
}
